import { useTranslation } from 'react-i18next';
import {
  DownloadViaLink,
  DownloadViaSMSButton,
} from '~/features/DownloadAppBanner';

export type GetAppModalProps = {};

export const GetAppModal = (_: GetAppModalProps) => {
  const { t } = useTranslation();

  return (
    <div className="h-auto flex flex-nowrap place-items-stretch max-w-[900px]">
      <div className="w-6/12 mb-12">
        <img
          alt="get app"
          decoding="async"
          loading="lazy"
          src="/assets/get-app-banner.png"
          srcSet={`/assets/get-app-banner@2x.png 2x, /assets/get-app-banner@3x.png 3x`}
          onLoad={(e) => {
            e.currentTarget.style.backgroundImage = 'none';
            e.currentTarget.style.backgroundColor = 'white';
          }}
        />
      </div>
      <div className="w-6/12 pl-8">
        <div className="w-[384px]">
          <DownloadViaLink showDownloadLinks={false} />
        </div>
        <div className="mt-10 mb-5">
          <DownloadViaSMSButton
            buttonClass="w-[384px]"
            label={t('downloadViaSMSForm')}
          />
        </div>
      </div>
    </div>
  );
};
