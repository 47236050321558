import { LabelHTMLAttributes, FC } from 'react';
import * as React from 'react';
import cn from 'classnames';

export const Label: FC<
  React.PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>>
> = ({ children, className, ...props }) => {
  return (
    <label
      className={cn('block text-gray-700 text-sm font-medium', className)}
      {...props}
    >
      {children}
    </label>
  );
};
