import { GhostButton, MobileMenuItem } from '~/components';
import { ForwardedRef, useEffect, useState } from 'react';
import { GetAppModal } from './GetAppModal';
import { Popover, positionRight } from '@setel/web-ui';
import cx from 'classnames';
import { trackUserEvent } from '~/shared';
import { EVENT_TYPES } from '~/shared/analytics.events';
import { useTranslation } from 'react-i18next';

type GetAppButtonProps = {
  openMode: 'dropdown' | 'new-tab';
  className?: string;
};

const GET_APP_LINK = 'https://app.adjust.com/q4brdgl';

export const GetAppButton = ({ className, openMode }: GetAppButtonProps) => {
  let { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      trackUserEvent(EVENT_TYPES.get_app, { action: 'click' });
    }
  }, [isOpen]);

  return openMode === 'new-tab' ? (
    <MobileMenuItem
      className={className}
      children={t('navbar.getApp')}
      leftIcon="mobile"
      onClick={async () => {
        await trackUserEvent(EVENT_TYPES.get_app, { action: 'click' });
        window.open(GET_APP_LINK, '_blank');
      }}
    />
  ) : (
    <div className={className}>
      <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger
          render={({ ref, ...props }) => (
            <GhostButton
              {...props}
              onClick={() => setIsOpen((b) => !b)}
              children={t('navbar.getApp')}
              leftIcon="mobile"
              ref={ref as ForwardedRef<HTMLButtonElement>}
            />
          )}
        />
        <Popover.Content
          position={positionRight}
          wrapperClass={cx('hidden xl:block', isOpen && 'z-50')}
          className="px-10 pt-8"
        >
          <GetAppModal />
        </Popover.Content>
      </Popover>
    </div>
  );
};
